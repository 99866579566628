<template>
  <div class="contact-us mt-4">
    <metainfo>
    </metainfo>
    <div class="main">
      <!-- previous page button -->
      <section class="section-back-button">
        <a href="/">
          <i class="fas fa-long-arrow-alt-left"></i>
        </a>
      </section>
      <section class="row summit_content mx-auto col-lg-11 col-sm-12 py-5" style="height: 600px">
        <div class="col-lg-6 col-sm-12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3987.4879380380066!2d30.066995375768197!3d-1.9583747367316648!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dca6808722df75%3A0xc60fc6f2a288fe90!2s34%20KN%2041%20St%2C%20Kigali!5e0!3m2!1sen!2srw!4v1704790301161!5m2!1sen!2srw"
            width="100%" height="400" style="border: 2px solid #118fd5" allowfullscreen=""
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="col-lg-6">
          <h1 class="font-weight-bold mt-md-0 mt-4">Contact Us</h1>
          <p class="text-muted mt-2">
            Reach out to us with any questions, feedback, or inquiries! Our
            dedicated team is here to assist you. Whether you have
            product-related queries or want to explore collaboration
            opportunities, we value your communication. Connect with us directly
            for immediate assistance via:
          </p>
          <div class="mt-4 mx-md-2  mx-sm-0 text-muted">
            <i class="fa fa-map-marker" style="font-size: 22px"></i>
            <p class="ml-md-4 ml-4 pl-md-0 pl-4 text-muted" style="word-wrap: break-word; margin-top: -25px">
              Address : 34 KN 41 Street, Kiyovu Kigali, Rwanda
            </p>
          </div>
          <p class="mt-2 mx-md-1 mx-sm-0 text-muted">
            <i class="fa fa-phone" style="font-size: 22px"></i>
            <span class="ml-2">Phone Number:+250 788930101</span>
          </p>
          <p class="row mt-2 mx-md-1 mx-sm-0 text-muted">
            <i class="fa fa-envelope ml-md-0 ml-3" style="font-size: 19px"></i>
            <span class="ml-2">RWBuild@RwandaBuildProgram.com</span>
          </p>
        </div>
      </section>

    </div>
    <footer_comp />
  </div>
</template>
<script>
import footer_comp from "@/components/footer.vue";
export default {
  name: "contact-us",
  components: {
    footer_comp,
  },
  metaInfo: {
    title: "Contact Us",
    meta: [
      {
        name: "description",
        content:
          "Our mission is to revolutionize the Rwandan tech startup culture by empowering software development with increased speed and quality.",
      },
       {
        property: "twitter:url",
        content: "https://rwandabuildprogram.com/contact-us/",
      }

    ]
  }
};
</script>

<style scoped lang="scss">
.section-back-button {
  background: #fff;
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
}

.contact-us {
  min-height: 100vh
}

.main {
  margin-bottom: 2.5rem;
}

.footer {
  position: relatative;
  bottom: 0;
  width: 100%;
}

li {
  list-style: none;
  font-size: 14px;
  font-family: proxima-nava;
}

h1 {
  border-bottom: 4px solid #118fd5;
  width: fit-content;
  font-size: 1.45rem;
}

.text-muted {
  font-size: 16px;
}

@media only screen and (max-width: 1000px) {
  .summit_content {
    height: auto !important;
  }

  .footer {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
  }
}</style>
