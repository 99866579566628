import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/animate.css";
import "@/assets/css/main.scss";
import "@/assets/css/main.scss";
import router from './router/index';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { createMetaManager } from 'vue-meta'
import $ from "jquery";
import { plugin as vueMetaPlugin } from 'vue-meta'

window.$ = $;
library.add(faUserSecret)
const app = createApp(App)
app.use(vueMetaPlugin)
  app.use(createMetaManager()) 
  .use(router).component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
