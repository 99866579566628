<template>
  <div></div>
</template>

<script>
export default {
  name: "navbar",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
