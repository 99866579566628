<template>
  <div class="timeline">
    <section class="cd-timeline js-cd-timeline">
      <div class="container max-width-lg cd-timeline__container">
        <div class="cd-timeline__block">
          <div class="cd-timeline__img cd-timeline__img--picture">
          </div>
          <div
            class="cd-timeline__content text-component"
            data-aos="fade-down"
            data-aos-delay="50"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
          >
            <!-- <h2>Title of section 2</h2> -->
            <p class="color-contrast-medium">
              The history of starts with mentoring at Klab, the Rwandan
              government tech hub, Kigali. The focus was on mentoring
              entrepreneurs to form teams, establish goals, and write a real
              plan which that team can execute. Analyzing opportunities for tech
              startups and their projects in Rwanda, there was an extremely high
              failure rate. Working with key stakeholders, we started to develop
              plans to turn that around.
            </p>

            <div class="flex justify-between items-center">
              <span class="cd-timeline__date">2013-2015</span>
            </div>
          </div>
        </div>
        <div class="cd-timeline__block">
          <div class="cd-timeline__img cd-timeline__img--movie"></div>
          <div
            class="cd-timeline__content text-component"
            data-aos="fade-down"
            data-aos-delay="50"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
          >
            <p class="color-contrast-medium">
              Only mentoring teams a couple of times a month on establishing
              realistic goals and well-researched plans were not producing
              results. It became clear that the software industry in Kigali was
              young and most teams fail due to poor or no software development
              process. Team members commitment to any project was low. The
              software industry was in trouble without a better culture of
              development with structure and process.
            </p>
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date">2015-2017</span>
            </div>
          </div>
        </div>
        <div class="cd-timeline__block">
          <div class="cd-timeline__img cd-timeline__img--picture"></div>
          <div
            class="cd-timeline__content text-component"
            data-aos="fade-down"
            data-aos-delay="50"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
          >
            <p class="color-contrast-medium">
              RWBuild launched. Select teams were chosen for a full time program
              that would actively mentor and guild teams. The primary objective
              was to help Rwandan software startups rapidly build and launch
              their product by introducing better development processes and
              structures. This process starts with a solid plan and research
              that quickly moves into the execution of that plan. We establish
              accountability and timelines with one overall goal for the team:
              to launch a fully working product.
            </p>
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date">2017</span>
            </div>
          </div>
        </div>
        <!-- 2018 Timeline  -->
        <div class="cd-timeline__block">
          <div class="cd-timeline__img cd-timeline__img--location"></div>
          <div
            class="cd-timeline__content text-component"
            data-aos="fade-down"
            data-aos-delay="50"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
          >
            <p class="color-contrast-medium">
              Members of RWBuild using our development process were producing
              products and real software launches. Our focus started to shift
              from the software development process to the business process,
              needs, and responsibilities as working software now needed to be
              sold and supported. RWBuild supported new platforms and ecosystem
              products.
            </p>

            <div class="flex justify-between items-center">
              <span class="cd-timeline__date">2018</span>
            </div>
          </div>
          <!-- cd-timeline__content -->
        </div>
        <!-- 2019 Timeline  -->
        <div class="cd-timeline__block">
          <div class="cd-timeline__img cd-timeline__img--movie"></div>
          <div
            class="cd-timeline__content text-component"
            data-aos="fade-down"
            data-aos-delay="50"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
          >
            <p class="color-contrast-medium">
              Two teams were 'graduated' that could support themselves as
              independent companies with products designed and built with the
              RWBuild process. RWBuild launches its ‘Candidate Program,
              including a two- decade senior-level American developer for
              multiple 3-week workshops. This program leveled up junior
              'candidate' developers, training, and 'capacity building' events
              with a role in building up the software industry in Kigali.
            </p>

            <div class="flex justify-between items-center">
              <span class="cd-timeline__date">2019</span>
            </div>
          </div>
        </div>
        <!-- 2020Timeline  -->
        <div class="cd-timeline__block">
          <div class="cd-timeline__img cd-timeline__img--location"></div>
          <div
            class="cd-timeline__content text-component"
            data-aos="fade-down"
            data-aos-delay="50"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
          >
            <p class="color-contrast-medium">
              RWBuild teams launched the MurugoCloud ecosystem and a host of
              platform products. RWBuild focus on bringing in investments for
              our teams and these projects as we refined our development process
              and continued pushing for a better culture of software development
              in Kigali.
            </p>

            <div class="flex justify-between items-center">
              <span class="cd-timeline__date">2020</span>
            </div>
          </div>
        </div>
        <!-- 2021Timeline  -->
        <div class="cd-timeline__block">
          <div class="cd-timeline__img cd-timeline__img--picture"></div>
          <div
            class="cd-timeline__content text-component"
            data-aos="fade-down"
            data-aos-delay="50"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
          >
            <p class="color-contrast-medium">
              RWBuild opens their Tech Lab, ‘Rooftop Ikawa Cafe’ for testing and
              proving products and hosting tech events. RWBuild team Guhemba
              ltd, launches a limited version of the payment product Guhemba,
              exclusively at the RWBuild tech hub, Rooftop Ikawa Cafe as their
              restaurant ordering and payment platform. RWBuild, with its
              partners, won a prestigious NIH grant to build 912Rwanda, the
              national Dispatch & Ambulance system with the Ministry of Health.
            </p>

            <div class="flex justify-between items-center">
              <span class="cd-timeline__date">2021</span>
            </div>
          </div>
        </div>
        <!-- 2022 Timeline -->
        <div class="cd-timeline__block">
          <div class="cd-timeline__img cd-timeline__img--movie"></div>
          <div
            class="cd-timeline__content text-component"
            data-aos="fade-down"
            data-aos-delay="50"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
          >
            <p class="color-contrast-medium">
              The year kicked off with the ‘RWBuild Code Summit’ and a
              three-weeks of workshops that guided over 50 developers to
              increase their skills and ability. The capacity building
              continued, with 10 of them earning paid internships in the RWBuild
              Candidate Program. RWBuild, with its partners, won a a 2nd
              prestigious grant from the NIHR to expand 912Rwanda, the national
              Dispatch & Ambulance system, with the Ministry of Health.
            </p>

            <div class="flex justify-between items-center">
              <span class="cd-timeline__date">2022</span>
            </div>
          </div>
          <!-- cd-timeline__content -->
        </div>
        <!-- 2023 Timeline -->
        <div class="cd-timeline__block">
          <div class="cd-timeline__img cd-timeline__img--picture"></div>
          <div
            class="cd-timeline__content text-component"
            data-aos="fade-down"
            data-aos-delay="50"
            data-aos-duration="500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
          >
            <p class="color-contrast-medium">
              Guhemba and EventsBash launched major updates and expanded their
              Real-World-Testing our tech lab, Rooftop Ikawa Cafe, with major
              public and private ‘testing’ events. With its partners, 2023 is
              also the year RWBuild launched the 912Rwanda, a Ministry of Health
              Dispatch and Ambulance solution.
            </p>
            <div class="flex justify-between items-center">
              <span class="cd-timeline__date">2023</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- cd-timeline -->
  </div>
</template>
<script>
export default {
  name: "Timeline",
  props: {},
  mounted() {
    document.getElementsByTagName("html")[0].className += " js";
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$assets: "~@/assets/";
@font-face {
  font-family: "Proxima Nova";
  src: url($assets + "font/ProximaNova-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@import "~@/assets/timeline/style.css";
.cd-timeline {
  background-color: transparent !important;

  .cd-timeline__content {
    border: 1px solid #ddd !important;
    box-shadow: none;
  }
  .container {
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 100%;
    }
  }
  .text-component p {
    color: rgb(16, 16, 16) !important;
    font-size: 17px !important;
    font-family: "Proxima Nova";
  }
  .cd-timeline__date {
    font-family: "Proxima Nova";
  }
}
</style>
