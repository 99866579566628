<template>
  <div class="about">
    <metainfo>
      <template v-slot:description="{ metainfo }">{{ metainfo.description }}</template>
    </metainfo>
    <!-- previous page button -->
    <section class="section-back-button">
      <a href="/">
        <i class="fas fa-long-arrow-alt-left"></i>
      </a>
    </section>
    <section class="mx-md-4 mx-3 px-md-4 px-sm-1 pt-4 mb-2">
      <div class="container-fluid">
        <div class="widget">
          <h1 class="font-bold">RWBuild Mission Statement</h1>
          <p>
            At RWBuild, our mission is to transform Rwanda’s tech startup
            landscape by advancing software development and fostering
            entrepreneurship. We’re committed to nurturing a vibrant software
            development culture, emphasizing speed, quality, and collaborative
            innovation.
          </p>
          <p>
            Our ‘MurugoCloud’ platform exemplifies our dedication, offering an
            integrated ecosystem to support Rwandan developers and their
            projects. We pride ourselves on a product-first philosophy, ensuring
            our offerings not only meet but surpass market expectations, driving
            organic growth and establishing industry benchmarks.
          </p>
          <p>
            Simultaneously, we champion a founder-first approach, empowering
            visionary leaders with the resources to realize their innovative
            ideas. This dual focus positions RWBuild as a leader in tech startup
            ecosystems, contributing significantly to Kigali’s rise as a premier
            tech hub in Africa.
          </p>
        </div>
        <!-- <div class="col-6">
          <img src="@/assets/images/912/912RW Team Feb 2023 - 2 (DONT USE).png" />
        </div> -->
      </div>
    </section>

    <!-- timeline  -->
    <section class="mx-4 px-md-4 px-sm-1 fadeInUp pt-4 mb-2">
      <div class="container-fluid">
        <div class="widget">
          <h4 class="font-bold">Our Timeline</h4>
          <div class="row">
            <div class="col-lg-12">
              <Timeline />
            </div>
            <div class="">
              <h5 class="display mx-3">
                The RWBuild timeline was a process unto its own. Over the years,
                RWBuild guided Rwandan teams to build a positive and productive
                culture of software development that strengthened the experience
                needed for developers. We are proud to witness the RWBuild
                experience and process included in a young Rwanda software tech
                industry.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="mx-4 px-md-4 px-sm-1 fadeInUp pt-4 mb-2">
      <div class="container-fluid">
        <div class="widget">
          <h4 class="font-bold">RWBuild's Startup Studio Model</h4>
        </div>
        <div class="row mx-2">
          <p>
            RWBuild’s novel Startup Studio Model has been a game-changer for
            tech startups in Rwanda, resulting in unprecedented levels of
            innovation and success. Our approach begins with identifying and
            refining innovative business ideas to address market needs and
            opportunities. We invest in these ideas, providing capital,
            strategic guidance, and operational support to nurture promising
            startups.
          </p>
          <p class="mt-2">
            Our team-building strategy is key, bringing together skilled
            developers, artists, and entrepreneurs to drive these ventures.
            Through rigorous mentorship and continuous learning programs,
            including workshops and regular product evaluations, we ensure a
            culture of constant growth and collaboration. We adopt a
            product-first philosophy, focusing on rapid prototyping and testing
            to swiftly bring high-quality products to market. This approach is
            backed by advanced testing methods and a meticulous process honed
            over years, fostering efficiency and innovation.
          </p>
          <p class="mt-2">
            Startups under our wing gain access to a wealth of resources, from
            legal and marketing to business services, while being part of a
            vibrant community that encourages entrepreneurial spirit. We
            maintain a strong focus on accountability and impact, supporting
            startups through various growth stages and retaining equity stakes
            with a clear exit strategy in mind. Our mission is to shape the
            future of the tech industry in Rwanda, making RWBuild a catalyst for
            groundbreaking ideas and sustainable business success.
          </p>
        </div>
      </div>
    </section>
    <!-- Socio-economic Impact of RWBuild -->
    <section class="mx-4 px-md-4 px-sm-1 fadeInUp pt-4 mb-2">
      <div class="container-fluid">
        <div class="widget">
          <h4 class="font-bold">
            Socio-economic Impact of RWBuild (academic review)
          </h4>
        </div>
        <p>
          RWBuild's innovative methods and processes have yielded impressive
          results in addressing the critical gap in Rwanda's software industry.
          Their focus on underutilized college graduates from low- and
          middle-income countries (LMICs) has proven to be a timely and
          impactful solution to the industry's challenges.
        </p>
        <p class="mt-2">
          The software industry in Rwanda has traditionally lagged behind other
          African nations in terms of speed and quality of software development.
          This has contributed to the underemployment and unemployment of youth,
          which is a significant socio economic challenge in many LMICs.
        </p>
        <p class="mt-2">
          RWBuild has identified this problem and has focused on harnessing the
          untapped potential of younger college graduates from LMICs. By
          providing them with the necessary skills and tools, RWBuild has
          contributed to increasing the success rate of tech enterprises and its
          member's ability to launch products that have an impact and ongoing
          users.
          RWBuild's methods and processes have been successful in turning around
          the fortunes of LMIC college graduates who are ready to learn and
          adopt a new ‘culture of software development.’ Through their process
          of capacitive building, RWBuild has trained a new generation of tech
          entrepreneurs with the necessary skills to succeed in the industry.
          RWBuild has achieved an impressive success rate, which is a testament
          to their innovative approach to addressing the challenges of the
          software industry in Rwanda.
        </p>
        <p class="mt-2">
          RWBuild's methods and processes have proven to be a convincing
          solution to the challenges facing the software industry in Rwanda. By
          focusing on LMIC college graduates and providing them with the
          necessary skills and tools, RWBuild has contributed to reducing youth
          unemployment rates and increasing the success rate of tech
          enterprises. <br />Their innovative approach is a model that can be
          replicated in other LMICs to address similar challenges. The Rwanda
          Build Program (RWBuild) is an organization based in Rwanda that aims
          to address the shortage of critical skills in the country's software
          industry.
        </p>
        <p class="mt-2">
          The organization focuses on initiatives that tap into the potential of
          college graduates from Low- and Middle-Income Countries (LMIC).
        </p>
        <p class="mx-5" style="font-style: italic">
          "Rwanda's software industry has been falling behind other African
          nations, with slow and low-quality software development leading to
          failed startup product launches. RWBuild's timely initiatives are
          impactful and aim to empower the youth to build impactful and useful
          software, which leads to a significant reduction in unemployment, a
          socio-economic challenge in many LMICs. Kigali's NGOs have a success
          rate of around 70% for ICT workshops when it comes to handpicking
          participants for job interviews, while RWBuild has an impressive +90%
          success rate, with over 60% of participants hired as Rwanda
          ‘high-income earners.’ The tech sector in Rwanda has only seen 3% GDP
          growth, which is far below expectations, and has a high failure rate
          of tech enterprises within the first two years—furthermore, ICT.
          RWBuild has demonstrated positive results as a tech hub with its novel
          capacity-building programs. The organization is now seeking to take
          these results and increase its scale - expand the impact and abilities
          in the coming years.”
        </p>
      </div>
    </section>
    <!-- Join Rwbuild section -->
    <section class="dark pt-3 mt-4 mb-3">
      <div class="widget">
        <div class="row mx-md-4 mx-0" id="join">
          <div class="container-fluid">
            <div class="col-md-12 col-sm-12">
              <h4 class="section-title font-bold" style="color: rgba(245, 245, 245, 0.872)">
                How to Join
              </h4>
              <article class="article" style="color: rgba(245, 245, 245, 0.872)">
                <p>
                  Are you interested in joining RWBuild? We welcome aspiring
                  founders, individuals seeking teams, and startups to be a part
                  of our community. You don't need to have a startup idea or
                  plan in place, but we do appreciate founders who have goals
                  and plans and can prove a product with the potential to
                  execute.
                </p>
                <p>
                  At RWBuild, we evaluate your needs and your project's
                  resources and timeline independently as we believe each
                  founder and project is on their own journey. Our program is
                  non-cohort-based, and there is no one size-fits-all approach.
                </p>
                <p>
                  Once you're onboard, you'll be expected to follow the RWBuild
                  Development Process and will be subject to Project Management
                  on-site. We have produced some of the best software products
                  in Rwanda and have raised funding for many teams.
                </p>
                <p>
                  If you're not a founder, don't worry, we have options for
                  normal members and may be able to help you join an ongoing
                  project.
                </p>
                <p>
                  Submit an application today, and let's make your startup dream
                  a reality with RWBuild!
                </p>
              </article>
              <div class="d-flex justify-content-center align-items-center my-4">
                <a href="https://candidate.rw/" target="_blank" style="color: rgba(245, 245, 245, 0.872)">
                  <button class="btn btn-primary primary mt-4">
                    Visit Candidate Site
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- other links -->
    <section class="mx-md-5 mx-3" id="other">
      <div class="container-fluid">
        <div class="widget">
          <div>
            <h4 class="heading font-bold pt-3">Social Media Links</h4>
            <div class="row d-flex flex-wrap">
              <div class="d-flex col-lg-3 col-md-6 col-sm-6 mb-4">
                <div class="card align-items-center">
                  <div class="image-card mx-5 my-4">
                    <img src="@/assets/images/tweeter.png" class="card-img-fluid image" />
                  </div>
                  <div class="card-body">
                    <div class="card-title">
                      <h5 class="text-dark font-weight-bold headin">
                        <a href="https://www.twitter.com/RwandaBuild" target="_blank">
                          Rwanda Build Program.(@RwandaBuild) | Twitter
                        </a>
                      </h5>
                    </div>
                    <div class="card-subtitle">
                      <p class="text-secondary paragraph">
                        The latest Tweets from Rwanda Build (@RwandaBuild).
                        Software startup accelerator in Kigali, Rwanda. Please
                        follow @CommonWorld and https://t.co/4i9MCxJQrO. Kigali,
                        Rwanda
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex col-lg-3 col-md-6 col-sm-6 mb-4">
                <div class="card align-items-center">
                  <div class="image-card mx-5 my-4">
                    <img src="@/assets/images/facebook.png" class="card-img-fluid image" />
                  </div>
                  <div class="card-body">
                    <div class="card-title">
                      <h5 class="text-dark font-weight-bold headin">
                        <a href="https://www.facebook.com/RWBuild" target="_blank">
                          Rwanda Build Program.(Rwanda Build Program) |
                          facebook.
                        </a>
                      </h5>
                    </div>
                    <div class="card-subtitle">
                      <p class="text-secondary paragraph">
                        Rwanda Build Program, Kigali, Rwanda. 613 likes · 4
                        talking about this. Rwanda Build Program (RWBuild) is a
                        startup accelerator in Kigali, Rwanda. Its goal is to
                        help guide Rwandan Software Startups...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex col-lg-3 col-md-6 col-sm-6 mb-4">
                <div class="card align-items-center">
                  <div class="image-card mx-5 my-4">
                    <img src="@/assets/images/linkedin.png" class="card-img-fluid image" />
                  </div>
                  <div class="card-body">
                    <div class="card-title">
                      <h5 class="text-dark font-weight-bold headin">
                        <a href="https://www.linkedin.com/company/rwanda-build-program/" target="_blank">
                          Rwdanda Build Program.(RWBuild program ) | linkedIn
                        </a>
                      </h5>
                    </div>
                    <div class="card-subtitle">
                      <p class="text-secondary paragraph">
                        Rwanda Build Program (RWBuild) is a software startup
                        tech hub in Kigali, Rwanda. Its goal is to help guide
                        Rwandan Software Startups with better development and
                        business process to get their product launched. The
                        overall goal of the program is to elevate the culture of
                        building software in Rwanda.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer_comp />
  </div>
</template>
<script>
import footer_comp from "@/components/footer.vue";
import Timeline from "@/components/Timeline.vue";
export default {
  metaInfo: {
    title: "Mission as a Tech Hub in Kigali",
    description: "Our mission is to revolutionize the Rwandan tech startup culture by empowering software development with increased speed and quality.",
    meta: [
      {
        property: "og:title",
        content: "Mission as a Tech Hub in Kigali",
      },
    
      {
        property: "og:description",
        content:
          "Our mission is to revolutionize the Rwandan tech startup culture by empowering software development with increased speed and quality..",
      },
      {
        property: "twitter:title",
        content: "Mission as a Tech Hub in Kigali",
      },
      {
        property: "twitter:description",
        content:
          "Our mission is to revolutionize the Rwandan tech startup culture by empowering software development with increased speed and quality..",
      },
       {
        property: "twitter:url",
        content: "https://rwandabuildprogram.com/about-us/",
      }
    ],
  },
  name: "about-us",
  components: {
    Timeline,
    footer_comp,
  },
};
</script> 
<style scoped lang="scss">
.primary {
  color: rgba(245, 245, 245, 0.872);
  background-color: #007bff;
  border-color: #007bff;
  padding: 10px;
  width: 200px;
  font-size: 16px;
}

.first-word {
  font-family: "proxima-nova";
  font-weight: 900 !important;
}

.section-back-button {
  background: #fff;
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;

  .fa {
    font-size: 40px;
    color: #118fd5;
    cursor: pointer;
  }
}

.image-card {
  margin: auto;
  vertical-align: middle;
}

.image {
  max-height: 140px;
}

.paragraph {
  line-height: inherit !important;
  font-size: 15px !important;
}

.headin {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  font-size: 16px !important;
  cursor: pointer;
  color: #007bff !important;
}

li,
p {
  font-size: 17px !important;
  font-family: "Proxima Nova";
  line-height: 30px;
}

ul li::before {
  content: "\2022";
  color: #118fd5;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  font-size: 24px;
  margin-left: -1em;
}

.widget h1 {
  border-bottom: 5px solid #118fd5;
  padding-bottom: 0px;
  display: inline-block;
  margin: 0px;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.widget h4 {
  margin-bottom: 20px;
}

img.expand {
  width: 25em;
}

img.africa {
  width: 20em;
}

img.expandable {
  width: 25em;
  margin-top: 20px;
}

img.expanding {
  width: 15em;
}

.header {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 35px !important;
}

.headings {
  font-size: 24px !important;
  margin-bottom: 20px;
  margin-top: 20px;
}

.build {
  font-size: 20px !important;
}

.darky {
  background-color: rgb(34, 33, 33);
}

.position {
  text-align: center;
}

@media only screen and (max-width: 375px) {

  body #bout,
  .ml-5,
  .mx-5 {
    margin-left: 0rem !important;
  }

  #bout,
  .mr-5,
  .mx-5 {
    margin-right: 0rem !important;
  }
}

@media only screen and (max-width: 414px) {

  body #bout,
  .ml-4,
  .mx-4 {
    margin-left: 0rem !important;
  }

  #bout,
  .mr-4,
  .mx-4 {
    margin-right: 0rem !important;
  }
}

@media only screen and (max-width: 375px) {

  body #titling,
  .ml-4,
  .mx-4 {
    margin-left: 1rem !important;
  }

  #titling,
  .mr-4,
  .mx-4 {
    margin-right: 1rem !important;
  }
}

@media only screen and (max-width: 414px) {

  body #titling,
  .ml-4,
  .mx-4 {
    margin-left: 1rem !important;
  }

  #titling,
  .mr-4,
  .mx-4 {
    margin-right: 1rem !important;
  }
}
</style>