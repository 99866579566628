<template>
  <div id="app">
    <navbar />
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
import navbar from "@/components/navbar.vue";
// import footer_comp from "@/components/footer.vue";
export default {
  name: "app",
  components: {
    navbar,
    // footer_comp
  },
  mounted() { },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  watch: {}
};
</script>
<style lang="scss"></style>
